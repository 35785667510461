import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersComponent } from './users.component';
import { UserModule } from './user/user.module';
import {
  NbInputModule,
  NbCardModule,
  NbSpinnerModule,
  NbPopoverModule,
  NbSelectModule,
  NbIconModule, NbButtonModule
} from '@nebular/theme';
import { FormsModule } from '@angular/forms';
import {NgxPaginationModule} from "ngx-pagination";
import {UserDetailsDialogComponent} from "./user-details-dialog/user-details-dialog.component";

@NgModule({
  declarations: [UsersComponent,UserDetailsDialogComponent],
  imports: [
    CommonModule,
    NbCardModule,
    UserModule,
    NbSpinnerModule,
    FormsModule,
    NbInputModule,
    NbPopoverModule,
    NbSelectModule,
    NgxPaginationModule,
    NbIconModule,
    NbButtonModule
  ]
})
export class UsersModule { }
