import {AfterViewInit, Component, HostListener, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {UsersService} from '../../Services/users/users.service';
// import {LocalDataSource} from 'ng2-smart-table';
import {NbDialogService} from '@nebular/theme';
import {SaveSnapshotModalComponent} from '../service/save-snapshot-modal/save-snapshot-modal.component';
import {ExecutingRequestComponent} from '../executing-request/executing-request.component';
import {SuccessHandlerService} from '../../Services/successHandler/success-handler.service';
import {ErrorHandlerService} from '../../Services/errorHandler/error-handler.service';
import {PaginationInstance} from "ngx-pagination";
import {UserDetailsDialogComponent} from "./user-details-dialog/user-details-dialog.component";
import {BreakpointObserver} from "@angular/cdk/layout";
import {LaunchDialogComponent} from "../connector/launch-dialog/launch-dialog.component";
import {RunConnectorDto} from "../../Models/connector/startConnector-dto";
import {alertUsersConnectorResponse} from "../../Models/connector/connector.response";
import {AlertLevelEnum} from "../../Models/enums/alertUsers.enum";
import {ConnectorService} from "../../Services/Connector/connector.service";
import {ShowcaseDialogComponent} from "../../pages/modal-overlays/dialog/showcase-dialog/showcase-dialog.component";
import {ConfirmModalComponent} from "../../admin/dashboard/status-card/confirm-modal/confirm-modal.component";


@Component({
  selector: 'ngx-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, AfterViewInit {


  // source: LocalDataSource = new LocalDataSource();

  /** a variable for users data */
  users: any;

  /** filtered users based on entry in search input */
  filteredUsers: any;
  /**text to type for ngModel */
  searchText: string = '';
  searchDepartment: string = '';
  /**array of search columns */
  searchColumns: string[] = environment.SEARCH_COLUMNS;

  organization_id: string = environment.ORGANIZATION_ID;

  selectedNotification = '';
  selectedAdmin = '';
  // selectedNiveaux: string[] = [];
  selectedNiveaux: string = '';
  selectedActivation: string = '';
  paginationSettings: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0
  };
  startIndex: number = 1;
  endIndex: number = 1;


  // tableSize: number[] = [10, 20, 50, 100];
  tableSize: string[] = ['10', '20', '50', '100'];
  selectedSize: string;
  mobileSize: boolean = false;
  noData: boolean = false;
  noDataMessage:any;


  setMobileTable(selector: string) {
    const tableEl = document.querySelector(selector);
    if (!tableEl)
      return;


    const thEls = tableEl.querySelectorAll('thead th');
    const tdLabels = Array.from(thEls).map((el: any) => el.innerText);

    tableEl.querySelectorAll('tbody tr').forEach(tr => {
      const children = Array.from(tr.children);
      children.forEach((td, ndx) => {
        td.setAttribute('label', tdLabels[ndx]);
      });

    });
  }

  ngAfterViewInit() {
    this.setMobileTable('#myTable');
  }


  constructor(private usersService: UsersService, private dialogService: NbDialogService,
              private successHandler: SuccessHandlerService, private errorHandler: ErrorHandlerService,
              private breakpointObserver: BreakpointObserver, private connectorService: ConnectorService,
              ) {}

  ngOnInit(): void {
    this.loadTableSize();
    this.getUsers();
    this.breakpointObserver.observe(`(max-width: 1102px)`).subscribe((result) => {
      this.mobileSize = result.matches;
      this.setMobileTable('#myTable');
    });
  }


  getUsers() {
    this.usersService.getUsers(this.organization_id).subscribe(response => {
      this.users = response;
      this.filteredUsers = this.users;
      this.paginationSettings.totalItems = this.filteredUsers.length;
    },
    error => {
      this.noData = true;
      this.noDataMessage = error;
    }
    )
  }

  // Method to handle page change event
  onPageChange(pageNumber: number) {
    this.paginationSettings.currentPage = pageNumber;
    this.filterUsers();
  }

  onTableSizeChange(event: any): void {
    if (!this.selectedSize)
      this.selectedSize = event;
    localStorage.setItem('tableSize', event)
    this.paginationSettings.itemsPerPage = isNaN(+event) || !this.tableSize.map(s => +s).includes(+event) ? +this.tableSize[0] : +event;
    this.paginationSettings.currentPage = 1;
    this.filterUsers();
  }
  loadTableSize() {
    const savedSize = localStorage.getItem('tableSize');
    if (savedSize) {
      this.selectedSize = savedSize;
    } else {
      this.selectedSize = this.tableSize[0]; // Default size
    }
    this.onTableSizeChange(this.selectedSize);
  }
  alert(user: any) {
    this.dialogService.open(SaveSnapshotModalComponent,
      {
        context:
          {
            content: `Voulez-vous notifier par SMS l\'utilisateur ${user.firstName} ${user.lastName} ?`,
            headerIcon: 'fas fa-bell',
            confirmButtonContent: '',
            DenyButtonContent: '',
            validateButtonContent: 'Notifier'
          }
      }
    ).onClose.subscribe((formData) => {
      if (formData && formData.answer) {
        let dialogRef = this.showAlertingUserModal();

        this.usersService.alertUser(this.organization_id, user.id).subscribe(response => {
            this.replaceObjects(response);
            dialogRef.close();
            this.successHandler.handleSuccess('Opération terminée avec succès.')
          }, error => {
            dialogRef.close();
            this.errorHandler.handleError('Opération échouée.')
          }
        )
      }
    })
  }


  /** Method implementation to filter users based on search criteria */
  filterUsers() {
    this.filteredUsers = this.users;
    // Filter based on notification
    if (this.selectedNotification === '1') {
      this.filteredUsers = this.users.filter(user => user.alerted);
    } else if (this.selectedNotification === '2') {
      this.filteredUsers = this.users.filter(user => !user.alerted);
    }
    if (this.selectedAdmin === '1') {
      this.filteredUsers = this.users.filter(user => user.admin);
    } else if (this.selectedAdmin === '2') {
      this.filteredUsers = this.users.filter(user => !user.admin);
    }
    // let selectedNiveauxFiltered;
    // Filter multiple niveaux
    // if (this.selectedNiveaux.includes('none')) {
    //   // If "All" is selected, apply notification filter and return
    //   if (this.selectedNotification === '1') {
    //     this.filteredUsers = this.filteredUsers.filter(user => user.alerted);
    //   } else if (this.selectedNotification === '2') {
    //     this.filteredUsers = this.filteredUsers.filter(user => !user.alerted);
    //   }
    // } else {
    //   selectedNiveauxFiltered = this.selectedNiveaux.filter(niveau => niveau !== 'none');
    //   if (selectedNiveauxFiltered.length > 0) {
    //     this.filteredUsers = this.filteredUsers.filter(user => selectedNiveauxFiltered.includes(user.alert_level));
    //   }
    // }

    if (this.selectedActivation === '1') {
      this.filteredUsers = this.filteredUsers.filter(user => user.enabled);
    } else if (this.selectedActivation === '2') {
      this.filteredUsers = this.filteredUsers.filter(user => !user.enabled);
    }

    if (this.selectedNiveaux !== 'none' && this.selectedNiveaux !== '') {
      this.filteredUsers = this.filteredUsers.filter(user => this.selectedNiveaux.includes(user.alert_level));
    }

    // Filter based on search text
    if (this.searchText) {
      this.filteredUsers = this.filteredUsers.filter(user => {
        return this.searchColumns.some(column => {
          if (user[column]) {
            let value = user[column].toString().toLowerCase();
            if (column === 'email') {
              value = value.split('@')[0];
            }
            return value.includes(this.searchText.toLowerCase());
          }
          return false;
        });
      });
    }

    // Filter based on jobDepartment
    if (this.searchDepartment) {
      this.filteredUsers = this.filteredUsers.filter(user => {
        return user.jobDepartment && user.jobDepartment.toLowerCase().includes(this.searchDepartment.toLowerCase());
      });
    }

    this.paginationSettings.totalItems = this.filteredUsers?.length || 0;
    const totalPages = Math.ceil(this.paginationSettings.totalItems / this.paginationSettings.itemsPerPage);
    if (this.paginationSettings.currentPage > totalPages) {
      this.paginationSettings.currentPage = 1;
    }

  }

  // Handle activation change
  onActivationChange(event: any) {
    this.filterUsers();
  }

  // Handle notification change
  onNotifChange(event: any) {
    // if(this.selectedNotification !== 'none')
    this.filterUsers();
  }

  // Handle notification change
  onAdminChange(event: any) {
    this.filterUsers();
  }

  // Handle niveaux change
  onNiveauChange(event: any) {
    // this.paginationSettings.currentPage = 1;
    this.filterUsers();
  }

  // Apply filter on search input change
  applyFilter() {
    this.filterUsers();
  }


  /**
   *   a function that opens a pop-up if one user or more are alerting

   */
  showAlertingUserModal() {
    return this.dialogService.open(ExecutingRequestComponent, {
      context: {
        message: `Envoie du sms de notification à l\'utilisateur ...`
      },
      closeOnBackdropClick: false,  // Désactive la possibilité de fermer en cliquant en dehors du dialogue
    });
  }


  replaceObjects(data: any) {
    this.filteredUsers = this.users = this.users.map(user => user.id === data.id ? data : user);
  }


  openPopUp(user: any) {
    this.dialogService.open(UserDetailsDialogComponent,
      {
        context: {
          user: user,
          alert: this.alert.bind(this)
        },
      });
  }
  showAlertingUsersModal(message: string, connector: any) {
    return this.dialogService.open(ExecutingRequestComponent, {
      context: {
        message,
        users: this.users,
        connector: connector,
      },
      closeOnBackdropClick: false,  // Désactive la possibilité de fermer en cliquant en dehors du dialogue
    });
  }

  stringToAlertLevelEnum(niveau: string) {
    switch (niveau) {
      case '0':
        return AlertLevelEnum.ALERT_LEVEL_0;
      case '1':
        return AlertLevelEnum.ALERT_LEVEL_1;
      case '2':
        return AlertLevelEnum.ALERT_LEVEL_2;
      case '3':
        return AlertLevelEnum.ALERT_LEVEL_3;
    }
  }

  handleSuccess(message: any, showNavigateToUserspageButton: boolean) {
    this.dialogService.open(ShowcaseDialogComponent, {
      context: {
        title: `Opération terminée avec succès`,
        message: message,
        color: '#28a745',
        navigateToUsersButton: showNavigateToUserspageButton,
        // message : this.message,
      },
    });
  }

  openAlertPopup() {
    let connector = {
      name: 'Notification des utilisateurs',
      connector_id: 'alert user',
    };
    this.dialogService.open(ConfirmModalComponent,
        {
          context: {
            content: `Voulez-vous alerter les utilisateurs du niveau ${this.selectedNiveaux} ?`,
            Action: 'Alerter',
            headerIcon: 'fa-bell'
          },
        }).onClose.subscribe((formData) => {
      if (formData) {
        let runConnectorDto = new RunConnectorDto();
        runConnectorDto.services = [];

        runConnectorDto.alert_level = this.stringToAlertLevelEnum(this.selectedNiveaux);
        runConnectorDto.sms_header = formData.sms_header;

        let executionMessage: string = `${connector.name} en cours...`;
        let executionResult: string = `Opération terminée avec succès`;
        let dialog = this.showAlertingUsersModal(executionMessage, connector);
        //run the connector
        this.connectorService.StartConnector(this.organization_id, connector.connector_id, runConnectorDto)
          .subscribe((response: alertUsersConnectorResponse) => {
              dialog.close();
              let showNavigateToUserspageButton: boolean = false;
              if (connector.connector_id == environment.alertUserId) {
                executionResult = `
                Utilisateurs notifiés : ${response.alertedUsers},
                Utilisateurs déjà notifiés : ${response.alreadyEnabledUsers},
                Utilisateurs en échec : ${response.failedUsers}
              `;
                showNavigateToUserspageButton = true;
              }
              this.handleSuccess(executionResult, showNavigateToUserspageButton);
            },
            error => {
              dialog.close();
              this.errorHandler.handleError(error);
            }
          )
      }
    });
  }

  resetUserTries(user: any) {
    this.dialogService.open(SaveSnapshotModalComponent,
      {
        context:
          {
            content: `Voulez-vous débloquer le compte de l'utilisateur ${user.firstName} ${user.lastName} ?`,
            headerIcon: 'fas fa-sync',
            confirmButtonContent: '',
            DenyButtonContent: '',
            validateButtonContent: 'Débloquer'
          }
      }
    ).onClose.subscribe((formData) => {
      if(formData) {
        this.usersService.resetUserTries(this.organization_id, user.id).subscribe((response) => {
          this.replaceObjects(response);
          this.successHandler.handleSuccess(`Le nombre de tentatives à été reinitialisé avec succès pour ${user.firstName} ${user.lastName}`);
        }, (error:any)=>{
          this.errorHandler.handleError('La réinitialisation du nombre de tentatives a échoué');
        });
      }
    });
  }

  // resetAllUsersTries(){
  //   this.dialogService.open(SaveSnapshotModalComponent,
  //     {
  //       context: {
  //         content: `Voulez-vous réinitialiser le nombre de tentatives de tout les utilisateurs ?`,
  //         headerIcon: 'fas fa-sync',
  //         confirmButtonContent: '',
  //         DenyButtonContent: '',
  //         validateButtonContent: 'Réinitialiser',
  //       }
  //     }).onClose.subscribe((formData)=>{
  //       if(formData) {
  //         this.usersService.resetAllUsersTries(this.organization_id).subscribe((response:any) => {
  //           this.successHandler.handleSuccess(`Réinitialisation de nombre de tentatives :\nSuccès : ${response.done}\nÉchecs: ${response.failed}`);
  //         }, (error:any)=>{
  //           this.errorHandler.handleError('La réinitialisation du nombre de tentatives a échoué');
  //         });
  //       }
  //   });
  // }
}
