<nb-card>
  <nb-card-body>

    <label class="search-label" for="search">Search:</label>
    <input nbInput [nbFilterInput]="dataSource" id="search" class="search-input">

    <table [nbTreeGrid]="dataSource" [nbSort]="dataSource" (sort)="updateSort($event)">

      <tr nbTreeGridHeaderRow *nbTreeGridHeaderRowDef="allColumns"></tr>
      <tr nbTreeGridRow *nbTreeGridRowDef="let row; columns: allColumns"></tr>

      <ng-container [nbTreeGridColumnDef]="customColumn">
        <th nbTreeGridHeaderCell [nbSortHeader]="getSortDirection(customColumn)" *nbTreeGridHeaderCellDef>
          {{customColumn}}
        </th>
        <td nbTreeGridCell *nbTreeGridCellDef="let row">
          <ngx-fs-icon [kind]="row.data.kind" [expanded]="row.expanded"></ngx-fs-icon>
          {{row.data[customColumn]}}
        </td>
      </ng-container>

      <ng-container *ngFor="let column of defaultColumns; let index = index"
                    [nbTreeGridColumnDef]="column"
                    [showOn]="getShowOn(index)">
        <th nbTreeGridHeaderCell [nbSortHeader]="getSortDirection(column)" *nbTreeGridHeaderCellDef>
          {{column}}
        </th>
        <td nbTreeGridCell *nbTreeGridCellDef="let row">{{row.data[column] || '-'}}</td>
      </ng-container>

    </table>

  </nb-card-body>
</nb-card>
