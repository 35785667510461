import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators, ValidationErrors } from '@angular/forms';
import { AlertLevelEnum } from '../../../Models/enums/alertUsers.enum';
import { SnapshotStatus } from '../../../Models/enums/snapshotStatus';
import { SmsHeaderEnum } from '../../../Models/enums/smsHeader';
import { environment } from '../../../../environments/environment';
import { NbTagComponent, NbTagInputAddEvent, NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-launch-dialog',
  templateUrl: './launch-dialog.component.html',
  styleUrls: ['./launch-dialog.component.scss']
})
export class LaunchDialogComponent {

  status = 'primary';

  alertUserId : string = environment.alertUserId;
  IMPORT_LDAP_ID : string = environment.IMPORT_LDAP_ID;

  @Input() connector: any;
  @Input() depends_on: any;
  @Input() confirmButtonContent: string;
  /** variable set to true when the connector to run is 'alert users' */
  @Input() alertLevel: boolean;
  options: string[] = ['Niveau 0','Niveau 1','Niveau 2','Niveau 3'];
  @Input() niveauToAlert :any;
  smsHeaderOptions = Object.values(SmsHeaderEnum);

  /** mails recipients addresses */
  recipients: Set<string>;

  /** a boolean variable to store the value of send mail checkbox */
  recipientsCheckBoxValue: boolean = true;

  form = new UntypedFormGroup({
    alertLevel: new UntypedFormControl('',),
    depends_on: new UntypedFormArray([]),
    sms_header: new UntypedFormControl('',),
    comment: new UntypedFormControl(''),
  });

  public constructor(protected ref: NbDialogRef<LaunchDialogComponent>){

  }

  ngOnInit(): void {
    this.recipients = new Set<string>(this.connector.recipients);

    const formArray = this.form.get('depends_on') as UntypedFormArray;
    for(let serviceWithSnaphsots of this.depends_on){
      formArray.push(new UntypedFormGroup({
        service: new UntypedFormControl(serviceWithSnaphsots.service_id),
        // checked: new FormControl(true),
        snapshot: new UntypedFormControl(''),
      }));
    }

    if(this.connector.connector_id == this.IMPORT_LDAP_ID){
      const initialRecipientControls = Array.from(this.recipients).map(recipient => new UntypedFormControl(recipient));
      this.form.setControl('recipientsControl', new UntypedFormArray(initialRecipientControls, Validators.required));
      // this.form.addControl('recipientsControl', new FormArray([], Validators.required));
      this.form.addControl('sendMail', new UntypedFormControl(true));
    }

    this.setDefaultSnapshot();

    if (this.connector.connector_id !== this.alertUserId) {
      this.form.get('depends_on').setValidators(this.snapshotValidator);
    }

    // this.niveauToAlert = this.options[+this.niveauToAlert];
  }

  setDefaultSnapshot(): void {
    const formArray = this.form.get('depends_on') as UntypedFormArray;
    let allSnapshotsValid = true;

    formArray.controls.forEach((group, i) => {
      const snapshots = this.depends_on[i].snapshots;
      const prodSnapshot = snapshots.find(snapshot => snapshot.snapTags.some(tag => tag.name === 'Prod'));

      if (prodSnapshot) {
        group.get('snapshot').setValue(prodSnapshot.snapshot_id);
      } else {
        allSnapshotsValid = false;
        group.get('snapshot').setErrors({ noProdSnapshot: true });
      }
    });

    if (!allSnapshotsValid) {
      this.form.setErrors({ invalidSnapshots: true });
    }
  }

  snapshotValidator(control: any): ValidationErrors | null {
    const dependsOnArray = control as UntypedFormArray;
    for (let group of dependsOnArray.controls) {
      if (!group.get('snapshot').value) {
        return { snapshotRequired: true };
      }
    }
    return null;
  }

  isFormInvalidForConnector(): boolean {
    return this.connector.connector_id !== this.alertUserId && this.form.hasError('invalidSnapshots');
  }

  toggleRecipients() {
    const recipientsControl = this.form.get('recipientsControl');
    if (recipientsControl) {
      this.recipientsCheckBoxValue = this.form.get('sendMail')?.value;
      if (this.recipientsCheckBoxValue) {
        recipientsControl.enable();
        recipientsControl.setValidators(Validators.required); // Ensure validators are set when enabled
        recipientsControl.updateValueAndValidity();
      } else {
        recipientsControl.disable();
        recipientsControl.clearValidators(); // Clear validators when disabled
        recipientsControl.updateValueAndValidity()
      }
    }
  }

  get recipientsControlValue(): UntypedFormArray {
    return this.form.get('recipientsControl') as UntypedFormArray;
  }

  onTagAdd(event: NbTagInputAddEvent): void {
    const { value, input } = event;
    // Check if the recipient already exists in the FormArray
    const recipientExists = this.recipientsControlValue.controls.some(control => control.value === value);
    if (!recipientExists) {
      if (value && this.isValidEmail(value)) {
        this.recipientsControlValue.push(new UntypedFormControl(value));
      }
    }
    input.nativeElement.value = '';
  }

  onTagRemove(event: any): void {
    const index = this.recipientsControlValue.controls.findIndex(control => control.value === event.text);
    if (index >= 0) {
      this.recipientsControlValue.removeAt(index);
    }
  }

  isValidEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }


  submit(){
    if(this.form.valid){
      this.ref.close(this.form.value);
    }
    else {
      this.status = 'danger'
      console.log('form is not valid');
      // this.ref.close()
    }

  }

  cancel(){
    this.ref.close();
  }

  onChange(event: any){
    if(this.form.get('alertLevel').valid){
      this.status = 'success';
    }
  }
}
